import useRoles from '@gr/portal/hooks/useRoles';
import { Auth0Role, ITestNumberDto } from '@gr/shared/models';
import { FastField, Formik } from 'formik';
import Checkbox from '../shared/Form/Checkbox';
import ClientDropdown from '../shared/Form/Dropdowns/ClientDropdown';
import { TextInput } from '../shared/Form/TextInput';
import { ITestNumberForm, testNumberFormSchema } from './types';

interface ITestNumberFormProps {
  item?: ITestNumberDto;
  onSubmit: (values: any) => void;
}

const HealthCheckNumberForm = ({ item, onSubmit }: ITestNumberFormProps): JSX.Element => {
  const initialFormState: ITestNumberForm = {
    name: item?.name ?? '',
    number: item?.number ?? undefined,
    clients: item?.clientId ? [{ label: item?.clientName ?? '', value: item?.clientId }] : [],
    allClients: item?.allClients ?? false,
  };
  const isGRStaffUser = useRoles([Auth0Role.GR_STAFF_USER]);

  const isReadOnly: boolean = item !== null && item !== undefined;

  return (
    <>
      <Formik
        initialValues={initialFormState}
        validationSchema={testNumberFormSchema}
        onSubmit={(values) => {
          onSubmit && onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
          <>
            <form id="test-number-form" onSubmit={handleSubmit} className="space-y-2">
              <FastField
                component={TextInput}
                id="name"
                name="name"
                label="Name"
                disabled={isReadOnly}
                value={values.name}
                error={touched.name ? errors.name : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <FastField
                component={TextInput}
                id="number"
                name="number"
                label="Number"
                type="number"
                disabled={isReadOnly}
                value={values.number}
                error={touched.number ? errors.number : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ClientDropdown
                value={values.clients ?? []}
                onChange={(newValue) => {
                  setFieldValue('clients', newValue);
                }}
                onBlur={() => {
                  setFieldTouched('clients');
                }}
                disabled={isReadOnly || values.allClients}
                placeholder={values.allClients ? '' : 'Search for a Client'}
                multiple
                showError={!!(touched.clients && errors.clients)}
                errorMessage={errors.clients as string}
                allClients={false}
              />
              {isGRStaffUser &&
                <Checkbox
                  id="allClients"
                  name="allClients"
                  label=""
                  description="Assign health check number to all of my clients"
                  disabled={isReadOnly}
                  checked={values.allClients}
                  onChange={handleChange}
                />
              }
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default HealthCheckNumberForm;
