import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import { Auth0OrganizationID, P2P_ORG_ID } from '@gr/shared/models';
import { configure } from 'axios-hooks';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import "react-datetime/css/react-datetime.css";
import { Route, Switch } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import { TokenResponse, configureAxiosWithAuth0 } from './authAxios';
import Login from './components/Login';
import { PageNotFound } from './components/shared/Auth/PageNotFound';
import { useMembership } from './contexts/MembershipContext';
import { initializeWebSocket } from './contexts/SocketContext';
import { useAuth0UserData } from './hooks/useAuth0Custom';
import { Auth0FrontendConfig } from './providers/auth0-data.provider';
import { Auth0LocalStorage, getCachedSelectedOrgName, removeP2pSession, setCachedSelectedOrgName } from './providers/auth0-storage.provider';
import { AUTH0_AUDIENCE, createAuth0Scope, logout } from './providers/auth0.service';

export interface IAppProps {
  auth0Config: Auth0FrontendConfig;
}

const App = ({
  auth0Config,
}: IAppProps): JSX.Element => {
  const {
    isAuthenticated: isLoggedIn,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    logout: auth0Logout,
    user: auth0User,
    loginWithRedirect,
  } = useAuth0UserData();

  const { setMembership, setMembershipLoaded, auth0LocalStorage } = useMembership();

  const [isConfigured, setIsConfigured] = useState(false);

  function getOrgSession(auth0LocalStorage: Auth0LocalStorage | undefined, orgId: Auth0OrganizationID) {
    return auth0LocalStorage
      ?.sessions
      ?.find(session => session?.user?.org_id === orgId);
  }

  useEffect(() => {
    if (isLoggedIn && auth0User && auth0LocalStorage && auth0Config) {
      const p2pSession = getOrgSession(auth0LocalStorage, P2P_ORG_ID);

      const isMissingUserId = !p2pSession?.user?.user_id || !auth0User?.user_id;
      const isDifferentUser = p2pSession?.user?.user_id !== auth0User?.user_id;

      if (isMissingUserId || isDifferentUser) {
        removeP2pSession();
        logout({ auth0Config, auth0Logout });
      }
    }
  }, [isLoggedIn, auth0User, auth0LocalStorage, auth0Config]);

  useEffect(() => {
    if (isConfigured) {
      return;
    }

    if (isLoggedIn) {
      const auth = async () => {
        try {
          await getAccessToken();
        } catch (e) {
          console.error(e);
        }
      };

      if (isLoggedIn) {
        auth();
      }

      initializeWebSocket(auth0Config);
    }
  }, [isLoggedIn, auth0Config, isConfigured]);

  useEffect(() => {
    if (isLoggedIn && !!auth0Config && !!auth0User) {
      setMembership({
        organization: auth0Config?.organization,
        roles: [auth0User?.auth_roles?.[0]],
      });

      const cachedOrgName = getCachedSelectedOrgName();

      if (isEmpty(cachedOrgName) || cachedOrgName !== auth0Config?.organization) {
        setCachedSelectedOrgName(auth0Config?.organization);
      }

      setMembershipLoaded(true);
    }
  }, [isLoggedIn, auth0Config, auth0User]);

  async function getAccessToken() {
    if (!auth0Config) {
      return;
    }

    let response: GetTokenSilentlyVerboseResponse | undefined = undefined;

    try {
      response = await getAccessTokenSilently({
        cacheMode: 'on',
        detailedResponse: true,
        authorizationParams: {
          organization: auth0Config.organizationId,
          audience: AUTH0_AUDIENCE,
          scope: createAuth0Scope({ orgName: auth0Config.organization }),
        }
      });
    } catch (error) {
      await loginWithRedirect({
        authorizationParams: {
          organization: auth0Config.organizationId,
          audience: AUTH0_AUDIENCE,
          scope: createAuth0Scope({ orgName: auth0Config.organization }),
        }
      });
    }

    if (response) {
      const token: TokenResponse = {
        id_token: response.id_token,
        access_token: response.access_token,
        expires_in: response.expires_in,
        scope: response.scope,
      };

      await configureAxios(token);
    }
  }

  async function configureAxios(token: TokenResponse) {
    if (!auth0Config) {
      return;
    }

    const authAxios = configureAxiosWithAuth0({
      auth0Config,
      token,
      auth0User,
      auth0Logout,
    });

    configure({
      axios: authAxios,
      defaultOptions: { useCache: false },
    });

    setIsConfigured(true);
  }

  return (
    <Switch>
      <Route path="/app" render={(props) => <AppWrapper configured={isConfigured} auth0Config={auth0Config} />} />
      <Route
        exact
        path="/"
        render={(props) => (
          <Login
            auth0Config={auth0Config}
          />
        )}
      />
      <Route
        exact
        path="/login"
        render={(props) => (
          <Login
            auth0Config={auth0Config}
          />
        )}
      />
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default App;
