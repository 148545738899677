import { useNotifications } from '@gr/portal/contexts/NotificationContext';
import useRoles from '@gr/portal/hooks/useRoles';
import {
  Auth0Role,
  ExternalV1CreateTestNumberRequest,
  ExternalV1DeleteAllTestNumberRequest,
  FilterDataTypeEnum,
  FilterOperatorEnum,
  IHttpResponse,
  ISearchRequest,
  ITestNumberDto
} from '@gr/shared/models';
import { IDeleteTestNumberRequest } from '@gr/shared/models/domain/test-numbers/delete-test-number-request.interface';
import { isSuperAdmin } from '@gr/shared/utils';
import useAxios from 'axios-hooks';
import { useState } from 'react';
import { axiosPost } from '../../authAxios';
import useTestNumbers from '../../hooks/useTestNumbers';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import Search from '../shared/Table/Search';
import { Table } from '../shared/Table/Table';
import { IColumn } from '../shared/Table/types';
import HealthCheckNumberDetailsPanel from './HealthCheckNumberDetailsPanel';
import { ITestNumberForm, defaultTestNumberTableOptions, getColumns, healthCheckFilterDropdownOptions } from './types';

/**
 * HealthCheckNumbers Component
 * 
 * A comprehensive management interface for health check phone numbers in the Grassroots portal.
 * This component provides CRUD operations for test phone numbers used in health check systems.
 * 
 * Key Features:
 * - Displays a searchable, filterable table of health check numbers
 * - Supports creating new test numbers
 * - Allows editing existing numbers
 * - Provides deletion capabilities (single client and all-client options)
 * - Includes role-based access control
 * 
 * Components Used:
 * - Table: For displaying and managing test number data
 * - Search: For filtering test numbers
 * - Button: For actions like Add, Delete
 * - HealthCheckNumberDetailsPanel: Side panel for creating/editing numbers
 * 
 * Context/Hooks Used:
 * - useNotifications: For displaying success/error messages
 * - useRoles: For role-based access control
 * - useTestNumbers: Custom hook for fetching test number data
 * - useAxios: For API calls
 */

const HealthCheckNumbers = () => {
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [searchText, setSearchText] = useState<number | string | undefined>(undefined);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [tableOptions, setTableOptions] = useState(defaultTestNumberTableOptions);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { addNotification } = useNotifications();
  const isGRStaffUser = useRoles([Auth0Role.GR_STAFF_USER]);

  /**
   * State Management:
   * @state showDetailsPanel - Controls visibility of the details side panel
   * @state searchText - Stores current search query
   * @state selectedItem - Currently selected test number for editing
   * @state tableOptions - Configuration for table display and filtering
   * @state deleteLoading - Loading state for delete operations
   */

  /**
   * Updates the table search options when filter/sort/pagination changes
   * @param searchOptions - New search configuration for the table
   */
  const handleSearchOptionChange = (searchOptions: ISearchRequest) => {
    setTableOptions(searchOptions);
  };

  /**
   * API Integration:
   * - Creates new test numbers
   * - Handles both single-client and multi-client scenarios
   * - Supports GR staff specific operations
   */
  const [{ data: createData, loading: createLoading, error: createError }, createTestNumber] = useAxios<
    IHttpResponse<string>
  >(
    {
      url: '/v1/test-numbers',
      method: 'POST',
    },
    { manual: true }
  );

  const [{
    data: testNumberResponse,
    loading: testNumberLoading,
    error: testNumberError
  }, refetch] = useTestNumbers(tableOptions);

  if (testNumberError) {
    console.error(testNumberError);
  }

  /**
   * Refreshes the test numbers data from the API
   * Silently handles errors to prevent UI disruption
   */
  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (err) { }
  };

  /**
   * Table Configuration:
   * - Defines column structure
   * - Handles custom formatting for phone numbers
   * - Sets up click handlers for row interactions
   */
  const columns = getColumns(
    {
      name: (item: ITestNumberDto) => {
        openDetailsPanel(item);
      },
    },
    {
      number: (item: ITestNumberDto) => {
        const numberAsString = item.number.toString();
        return (
          <>{`(${numberAsString.substring(0, 3)}) ${numberAsString.substring(3, 6)}-${numberAsString.substring(6)}`}</>
        );
      },
    }
  );

  /**
   * Opens the details panel for viewing/editing a test number
   * Prepares the selected item with additional client information
   * @param item - The test number record to display
   */
  const openDetailsPanel = (item?: ITestNumberDto) => {
    if (item && !testNumberLoading) {
      const clientIds = (testNumberResponse?.data as unknown as ITestNumberDto[]).map((x) => x.clientId);
      const itemToPass = {
        ...item,
        name: item.clientName,
        number: item.number,
        clientIds: clientIds,
        allClients: isSuperAdmin,
      };
      setSelectedItem(itemToPass);
    } else {
      setSelectedItem(item);
    }
    setShowDetailsPanel(true);
  };

  /**
   * Form Submission Handler:
   * Creates a new test number with specified client associations
   * Supports both single-client and all-client scenarios for GR staff
   * 
   * @param formData - Contains name, number, and client assignments
   */
  const handleSubmit = async (formData: ITestNumberForm) => {
    const request: ExternalV1CreateTestNumberRequest = {
      name: formData.name,
      number: formData.number!,
      clientIds: formData.clients && formData.clients.length > 0
        ? formData.clients?.map((x) => x.value as string)!
        : undefined,
      hasAllClients: isGRStaffUser ? formData.allClients : undefined
    };
    try {
      await createTestNumber({ data: { ...request } });
      closeDetailsPanel();
      handleRefetch();
      addNotification({ header: 'Health Check Number created successfully' });
    } catch (err) {
      console.log('error occurred');
      console.error(err);
    }
  };

  /**
   * Delete Operations:
   * Two deletion modes:
   * 1. handleDelete: Removes number for specific client
   * 2. handleDeleteAll: Removes number across all clients (GR staff only)
   */
  const handleDelete = async () => {
    if (!selectedItem) {
      return;
    }

    const deleteRequest: IDeleteTestNumberRequest = {
      id: selectedItem.id,
      clientId: selectedItem.clientId,
    };

    setDeleteLoading(true);

    try {
      await axiosPost('/v1/test-numbers-delete', deleteRequest);
      closeDetailsPanel();
      handleRefetch();
      addNotification({ header: 'Health Check Number deleted successfully' });
    } catch (err) {
      console.error(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  /**
   * Deletes a test number across all clients
   * Only available for users with appropriate permissions
   */
  const handleDeleteAll = async () => {
    if (!selectedItem) {
      return;
    }
    setDeleteLoading(true);

    try {
      const deleteRequest: ExternalV1DeleteAllTestNumberRequest = {
        number: selectedItem.number,
        hasAllClients: true
      };

      await axiosPost('/v1/test-numbers-delete-all', deleteRequest);
      closeDetailsPanel();
      handleRefetch();
      addNotification({ header: 'Health Check Number deleted successfully for all assigned Clients' });
    } catch (err) {
      console.error(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  /**
   * Closes the details panel and clears the selected item
   */
  const closeDetailsPanel = () => {
    setSelectedItem(undefined);
    setShowDetailsPanel(false);
  };

  const filterColumns: IColumn[] = columns.filter((col) => ['number', 'clientName'].includes(col.fieldName));

  /**
   * Search Implementation:
   * Handles numeric search filtering for test numbers
   * Updates table options with appropriate filters
   * 
   * @param overrideText - Optional override for the search text
   */
  const handleSearch = (overrideText?: string | number) => {
    // Remove any non-numeric characters from the search text
    const numbersOnly = typeof searchText === 'string'
      ? searchText.replace(/\D/g, '')
      : searchText;

    // Use the override value if provided, otherwise use current searchText
    const searchValue = overrideText !== undefined
      ? overrideText
      : numbersOnly;

    // Update the searchText state if we have an override
    if (overrideText !== undefined) {
      setSearchText(overrideText);
    }

    // Filter out any existing number filters
    const nonSearchFilters = tableOptions.filters.filter(
      filter => filter.fieldName !== 'number'
    );

    // Only add the number filter if there's a search value
    const filters = searchValue
      ? [
        ...nonSearchFilters,
        {
          dataType: FilterDataTypeEnum.NUMBER,
          fieldName: 'number',
          operator: FilterOperatorEnum.CONTAINS,
          value: searchValue
        }
      ]
      : nonSearchFilters;

    // Update table options with the new filters
    setTableOptions({
      ...defaultTestNumberTableOptions,
      filters
    });
  };

  /**
   * Maps test numbers to DTO format.
   * Ensures consistent data structure for display in the UI.
   * Preserves all essential fields while maintaining type safety.
   * 
   * @param {ITestNumberDto[]} tnm - The test numbers to map
   * @returns {ITestNumberDto[]} The formatted test number DTOs
   */
  function mapTestNumbersToDto(tnm: ITestNumberDto[]): ITestNumberDto[] {
    return tnm?.map((x) => {
      return {
        id: x.id,
        number: x.number,
        name: x.name,
        clientId: x.clientId,
        carrierName: x.carrierName,
        clientName: x.clientName,
        createdAt: x.createdAt,
        updatedAt: x.updatedAt,
        allClients: x.allClients,
      } as ITestNumberDto;
    });
  }

  /**
   * Component Layout:
   * Renders a hierarchical structure:
   * 1. Title
   * 2. Search and Add button row
   * 3. Main data table
   * 4. Details panel (conditional render)
   */
  return (
    <>
      <h2 id="test-number-title" className="pb-2 dark:text-white">
        Health Check Numbers
      </h2>
      <div className="flex justify-between pb-2">
        <Search
          id="number"
          name="number"
          placeholder="Number"
          searchText={searchText}
          setSearchText={setSearchText}
          handleSearch={handleSearch}
          tableOptions={tableOptions}
        />
        <Button
          id="test-number-add"
          className="self-end"
          variant={ButtonVariantEnum.SECONDARY}
          onClick={() => {
            setShowDetailsPanel(true);
          }}
        >
          Add
        </Button>
      </div>
      <Table
        shimmer
        loading={testNumberLoading}
        columns={columns}
        items={mapTestNumbersToDto(testNumberResponse?.data as ITestNumberDto[])}
        count={(testNumberResponse?.page?.total ?? 0)}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={handleSearchOptionChange}
        filter
        filterColumns={filterColumns}
        filterDropdownOptions={healthCheckFilterDropdownOptions}
        paginate
      />
      <HealthCheckNumberDetailsPanel
        errorMessage={createError?.response?.data?.message}
        show={showDetailsPanel}
        loading={createLoading}
        onClosePanel={closeDetailsPanel}
        selectedItem={selectedItem}
        handleSubmit={handleSubmit}
        leftPanelButtonOptions={[
          {
            text: 'Delete',
            visible: !!selectedItem,
            variant: ButtonVariantEnum.DELETE,
            loading: deleteLoading,
            onClick: handleDelete,
          },
          {
            text: 'Delete All',
            visible: !!selectedItem,
            variant: ButtonVariantEnum.DELETE,
            loading: deleteLoading,
            onClick: handleDeleteAll,
          },
        ]}
      />
    </>
  );
};

export default HealthCheckNumbers;
