import { Button, ButtonVariantEnum, LoadingIndicator, ProgressBar } from '@Wonder-Cave/ui';
import { CampaignStatusEnum, CampaignTypeEnum, MessageTypeEnum } from '@gr/shared/enums';
import {
  ContactListStatusEnum,
  ExternalV1CampaignDetailsResponse,
  ExternalV1Domain,
  ExternalV1UpdateStatusRequest,
  ICheckUniqueCampaignNameResponse,
  IExternalV1CampaignCreationRequest,
  IExternalV1CampaignCreationResponse,
  IHttpResponse,
} from '@gr/shared/models';
import { AxiosResponse } from 'axios';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { axiosGet, axiosPatch, axiosPost, axiosPut } from '../../authAxios';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import useDomains from '../../hooks/useDomains';
import { IDropdownValue } from '../shared/Form/Dropdown';
import Build from './Steps/Build';
import Plan from './Steps/Plan';
import Preview from './Steps/Preview';
import { campaignBuildFormSchema, campaignPlanFormSchema, campaignPreviewFormSchema } from './Steps/types';
import { getCampaignEndDateForClone, getCampaignEndDateForStartDate, getCampaignStartDate } from './services/campaigns.service';
import { ICampaignForm } from './types';

interface CampaignCreateRequest extends IExternalV1CampaignCreationRequest {
  id?: string;
  suppressionAreaCodesRaw: Array<IDropdownValue>;
}

const CreateCampaign = () => {
  const { id } = useParams<any>();
  const [saveExitLoading, setSaveExitLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [stepNumber, setStepNumber] = useState(0);
  const [nextLoading, setNextLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [leads, setLeads] = useState(0);
  const { addNotification } = useNotifications();
  const [{ data: domainsData, loading: domainsLoading, error: domainsError }] = useDomains();
  const [submissionError, setSubmissionError] = useState<string>();
  const [formErrors, setFormErrors] = useState<{ [key: string]: string; }>({});

  const startsAt = getCampaignStartDate();

  const [formData, setFormData] = useState<ICampaignForm>({
    name: '',
    status: CampaignStatusEnum.DRAFT,
    contactLists: [],
    allListsFailed: false,
    showSuppressions: false,
    suppressionAreaCodes: [],
    suppressionLists: [],
    suppressionStates: [],
    campaignType: undefined,
    messageType: MessageTypeEnum.SMS,
    message: '',
    twoWayEnabled: false,
    clickTrackingEnabled: false,
    generateUniqueLinks: false,
    url: '',
    domainId: '',
    domain: '',
    startsAt: startsAt,
    endsAt: getCampaignEndDateForStartDate(startsAt),
    timezonePrioritizationEnabled: true
  });

  const submitPlan = async (formikProps) => {
    try {
      // Clear previous form errors
      setFormErrors({});

      setNextLoading(true);

      // Touch all required fields
      let errors = await formikProps.setTouched({
        name: true,
        client: true,
        messageType: true,
        campaignType: true,
        contactLists: true,
        clickerGroup: true,
      });

      // Validate name is unique per tenant
      if (!id) {
        const name = formikProps.values.name;
        const checkNameResp = await axiosPost<IHttpResponse<ICheckUniqueCampaignNameResponse>>(`/campaigns/validate-name`, { name });
        console.log(checkNameResp);
        if (!checkNameResp?.data?.data?.isValid) {
          const invalidNameMsg = `A campaign with the name "${name}" already exists`;

          setFormErrors({
            ...formErrors,
            name: invalidNameMsg
          });

          errors.name = invalidNameMsg;
        }
      }

      if (Object.keys(errors).length <= 0) {
        setFormData(formikProps.values);
        setStepNumber((prevState) => prevState + 1);
      }
    } finally {
      setNextLoading(false);
    }
  };

  const submitBuild = async (formikProps) => {
    setNextLoading(true);
    try {
      const touched = {};
      Object.keys(formikProps.errors).forEach((k) => (touched[k] = true));
      formikProps.setTouched(touched);
      let errors = await formikProps.setTouched({
        message: true,
        startsAt: true,
        endsAt: true,
        clickTrackingEnabled: true,
        url: true,
        domainId: true,
        domain: true
      });
      if (Object.keys(errors).length <= 0) {
        setFormData({ ...formikProps.values });
        setStepNumber((prevState) => prevState + 1);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setNextLoading(false);
    }
  };

  const saveDraft = async (formikProps, exit: boolean) => {
    exit ? setSaveExitLoading(true) : setSaveLoading(true);
    try {
      const touched = {};
      Object.keys(formikProps.errors).forEach((k) => (touched[k] = true));
      formikProps.setTouched(touched, false);
      if (Object.keys(await formikProps.validateForm()).length <= 0) {
        const response: AxiosResponse<IHttpResponse<IExternalV1CampaignCreationResponse>> = await saveCampaign({
          ...formikProps.values,
          name: formikProps.values.name.trim(),
          contactListIds: formikProps.values.contactLists.map((cl) => cl.value),
          clientId: formikProps.values.client.value,
          type: formikProps.values.campaignType,
          message: formikProps.values.message.length > 0 ? formikProps.values.message : ' ',
          domainId: formikProps.values.domainId || undefined,
          clickerGroupId: formikProps.values.clickerGroup || undefined,
          suppressionListIds: formikProps.values.suppressionLists.map((sl) => sl.value),
          suppressionAreaCodesRaw: formikProps.values.suppressionAreaCodes,
          suppressionStates: formikProps.values.suppressionStates.map((ss) => ss.value),
          startsAt: formikProps.values.startsAt ?? getCampaignStartDate(),
          endsAt: formikProps.values.endsAt ?? getCampaignEndDateForStartDate(new Date()),
          externalId: formikProps.values.externalId ?? '',
          enableTwoWay: formikProps.values.twoWayEnabled,
        }, undefined, formikProps);
        if (response?.data?.data) {
          addNotification({ header: 'Draft campaign saved successfully.', type: NotificationType.SUCCESS });
          if (exit) {
            history.push('/app/campaigns');
          } else {
            setFormData({ ...formikProps.values, id: response?.data?.data.campaignId ?? id ?? formikProps.values.id });
            return response;
          }
        }
        else {
          addNotification({ header: 'Draft campaign failed to save.', type: NotificationType.FAILURE });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      exit ? setSaveExitLoading(false) : setSaveLoading(false);
    }
  };

  const submitPreview = async (formikProps) => {
    setNextLoading(true);
    try {
      const response: AxiosResponse<IHttpResponse<object>> = await saveCampaign(
        {
          ...formikProps.values,
          name: formikProps.values.name.trim(),
          contactListIds: formikProps.values.contactLists.map((cl) => cl.value),
          clientId: formikProps.values.client.value,
          type: formikProps.values.campaignType,
          message: formikProps.values.message.length > 0 ? formikProps.values.message : ' ',
          domainId: formikProps.values.domainId || undefined,
          clickerGroupId: formikProps.values.clickerGroup || undefined,
          suppressionListIds: formikProps.values.suppressionLists.map((sl) => sl.value),
          suppressionAreaCodesRaw: formikProps.values.suppressionAreaCodes,
          suppressionStates: formikProps.values.suppressionStates.map((ss) => ss.value),
          startsAt: formikProps.values.startsAt ?? getCampaignStartDate(),
          endsAt: formikProps.values.endsAt ?? getCampaignEndDateForStartDate(new Date()),
          externalId: formikProps.values.externalId ?? '',
          enableTwoWay: formikProps.values.twoWayEnabled,
        },
        {
          status: CampaignStatusEnum.LIVE,
        }
      );
      if (response?.status === 200) {
        addNotification({ header: 'Campaign saved successfully.', type: NotificationType.SUCCESS });
        history.push('/app/campaigns');
      }
      else {
        addNotification({ header: 'Campaign failed to save.', type: NotificationType.FAILURE });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setNextLoading(false);
    }
  };

  const getCampaign = async () => {
    setLoading(true);
    try {
      const response = await axiosGet(`/v1/campaigns/${id}`);
      const campaign: ExternalV1CampaignDetailsResponse = response?.data?.data;
      const contactListResponses = await Promise.all(
        campaign.contactListIds.map((id) => axiosGet(`/v1/contact-lists/${id}`))
      );
      const contactListOptions = contactListResponses
        ?.map(clr => clr.data?.data)
        .filter(cl => cl?.status !== ContactListStatusEnum.FAILED)
        .map((cl) => ({
          label: cl.name,
          value: cl.id,
        }));

      const suppressionListResponses = await Promise.all(
        campaign.suppressionListIds.map((id) => axiosGet(`/v1/contact-lists/${id}`))
      );

      // If click tracking, get a domain ID
      let domain;
      if (campaign.clickTrackingEnabled && campaign.domainId) {
        const domainResponse = await axiosGet<IHttpResponse<ExternalV1Domain>>(`/v1/domains/${campaign.domainId}`);
        domain = domainResponse?.data?.data?.url;
      }

      setFormData({
        ...campaign,
        name: campaign.name ?? '',
        client: { label: campaign.clientName, value: campaign.clientId },
        campaignType: campaign.type ?? CampaignTypeEnum.FUNDRAISING,
        contactLists: contactListOptions,
        allListsFailed: contactListOptions?.length === 0 && !!contactListResponses?.length,
        // Show suppressions if we have any
        showSuppressions:
          suppressionListResponses?.length > 0 ||
          campaign.suppressionAreaCodes?.length > 0 ||
          campaign.suppressionStates?.length > 0,
        suppressionLists: suppressionListResponses.map((slr) => ({
          label: slr.data?.data?.name,
          value: slr.data?.data?.id,
        })),
        suppressionAreaCodes: campaign.suppressionAreaCodes.map((sac) => ({
          label: sac.toString(),
          value: sac,
          additionalData: sac,
        })),
        suppressionStates: campaign.suppressionStates.map((ss) => ({ label: ss, value: ss })),
        clickerGroup: campaign.clickerGroupId ?? '', // None condition
        messageType: campaign.messageType,
        startsAt: new Date(campaign.startsAt) < new Date() ? getCampaignStartDate() : new Date(campaign.startsAt),
        endsAt:
          new Date(campaign.startsAt) < new Date()
            ? getCampaignEndDateForClone(getCampaignStartDate(), new Date(campaign.endsAt))
            : getCampaignEndDateForClone(new Date(campaign.startsAt), new Date(campaign.endsAt)),
        url: campaign.url ?? undefined,
        domainId: campaign.domainId ?? undefined,
        domain,
        // MMS set a dummy file for validation
        mediaFile:
          campaign.messageType === MessageTypeEnum.MMS ? new File([''], 'filename.png', { type: 'image' }) : undefined,
        generateUniqueLinks: campaign.generateUniqueLinksEnabled,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const saveCampaign = async (campaign?: CampaignCreateRequest, statusRequest?: ExternalV1UpdateStatusRequest, formikProps?: any) => {
    let response;
    setSubmissionError(undefined);
    try {
      const request = {
        ...campaign,
        suppressionAreaCodes: campaign?.suppressionAreaCodesRaw?.map((sac) => sac.additionalData),
      };

      response = campaign?.id
        ? await axiosPut(`/v1/campaigns/${campaign?.id}`, request)
        : await axiosPost(`/v1/campaigns`, request);

      if (response?.status === 200 && statusRequest) {
        // We override the response here because we only care about success
        // If this PATCH is successful, we can safely assume the create/update was successful too
        response = await axiosPatch(`/v1/campaigns/${campaign?.id ?? response?.data?.data?.campaignId}`, statusRequest);
      }
    } catch (e: any) {
      console.error('create campaign error:', e);

      const status = e.response?.status;
      const message = (status >= 400 && status < 500)
        ? (e.response?.data?.errors?.[0] ?? e.response?.data?.message ?? e.response?.data ?? e.message)
        : 'Internal Server Error. Failed to save campaign.';

      const lowerMessage = message.toLowerCase();
      if (stepNumber === 0 && lowerMessage.includes('a campaign with the name') && lowerMessage.includes('already exists')) {
        formikProps?.setTouched({
          name: true
        });
        await setFormErrors({
          name: message
        });
      } else {
        setSubmissionError(message);
      }
    } finally {
      return response;
    }
  };

  useEffect(() => {
    if (id) {
      getCampaign();
    }
  }, [id]);

  const stepConfigs = [
    {
      submit: submitPlan,
      schema: campaignPlanFormSchema,
    },
    {
      submit: submitBuild,
      schema: campaignBuildFormSchema,
    },
    {
      submit: submitPreview,
      schema: campaignPreviewFormSchema, // No schema for this one, nothing to validate
    },
  ];

  const Step = ({ stepNumber, formErrors, ...rest }) => {
    switch (stepNumber) {
      case 0:
        return <Plan onSubmit={stepConfigs[stepNumber].submit} leads={leads} setLeads={setLeads} setLoading={setLoading} {...rest} submissionError={submissionError} allListsFailed={formData.allListsFailed} formErrors={formErrors} />;
      case 1:
        return <Build onSubmit={stepConfigs[stepNumber].submit} leads={leads} domains={domainsData?.data} {...rest} submissionError={submissionError} />;
      case 2:
        return <Preview campaign={formData as any} submissionError={submissionError} saveLoading={saveLoading} saveDraft={saveDraft} sending={sending} setSending={setSending} {...rest} />;
      default:
        return <Plan onSubmit={stepConfigs[stepNumber].submit} {...rest} leads={0} setLeads={() => { }} setLoading={setLoading} submissionError={submissionError} allListsFailed={formData.allListsFailed} />;
    }
  };
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between w-full mb-8 px-28">
        <h1>{formData?.name && stepNumber === 2 ? formData?.name : 'Create Campaign'}</h1>
        <Button isLoading={saveExitLoading} disabled={nextLoading || sending} type="submit" formId="campaign-form" variant={ButtonVariantEnum.TERTIARY}>
          SAVE DRAFT & EXIT
        </Button>
      </div>
      <ProgressBar
        className="-ml-4 w-[calc(100%+2rem)]"
        steps={[
          { num: 1, description: 'Plan', active: stepNumber === 0 },
          { num: 2, description: 'Build', active: stepNumber === 1 },
          { num: 3, description: 'Preview', active: stepNumber === 2 },
        ]}
      />
      <div className="relative mt-8 px-28">
        {loading && (
          <div
            className="flex absolute z-20 w-[calc(100%+2rem)] h-[calc(100%+2rem)] -ml-32 -mt-8 backdrop-blur-[1.5px]"
            aria-hidden="true"
          >
            <div className="mx-auto mt-1/5">
              <LoadingIndicator size={6} />
            </div>
          </div>
        )}
        <Formik
          initialValues={formData}
          validationSchema={stepConfigs[stepNumber].schema}
          onSubmit={stepConfigs[stepNumber].submit}
          enableReinitialize
          initialStatus={true}
        >
          {(props) => {
            return (
              <form
                id="campaign-form"
                onSubmit={(event) => {
                  event.preventDefault();
                  saveDraft(props, true);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                  }
                }}
              >
                <Step stepNumber={stepNumber} formErrors={formErrors} {...props} />
                <div className="flex justify-end w-full pb-2 mt-auto">
                  <Button
                    variant={ButtonVariantEnum.SECONDARY}
                    className="mr-4"
                    type="button"
                    onClick={() => (stepNumber === 0 ? history.goBack() : setStepNumber((prevState) => prevState - 1))}
                  >
                    BACK
                  </Button>
                  <Button type="button" isLoading={nextLoading} onClick={() => stepConfigs[stepNumber].submit(props)} disabled={loading || saveExitLoading || sending}>
                    {stepNumber === 2 ? 'SAVE & EXECUTE' : 'NEXT'}
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default CreateCampaign;
