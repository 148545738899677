import { Auth0ApplicationID, Auth0OrganizationID, Auth0OrganizationName } from '@gr/shared/models';
import { addParam, getRedirectUrlByOrg } from '@gr/shared/utils';
import { useLocation } from 'react-router-dom';

export interface Auth0FrontendConfig {
  label: string;
  clientId: Auth0ApplicationID;
  organization: Auth0OrganizationName,
  organizationId: Auth0OrganizationID;
  apiUrl: string;
  websocketUrl: string;
  gsdUrl: string;
}

export function getUrlParam(name: string) {
  const params = new URLSearchParams(useLocation().search);
  return params.has(name) ? (params.get(name) ?? undefined) : undefined;
}

export function getSubDomain() {
  return window?.location?.host?.split('.')?.[0];
}

export interface GotToOrgRequest {
  orgId: Auth0OrganizationID | undefined;
  orgName: Auth0OrganizationName | undefined;
  invitation?: string | undefined;
}

export function goToOrg({ orgId, orgName, invitation }: GotToOrgRequest) {
  const url = getRedirectUrlByOrg({ baseUrl: window?.location?.origin, orgId, orgName });
  window.location.replace(!!invitation ? addParam(url, `invitation=${invitation}`) : url);
}
