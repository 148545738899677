import { ISearchRequest } from '@gr/shared/models';
import { SearchIcon } from '@heroicons/react/solid';
import { Button } from '../Buttons/Button';
import { ButtonVariantEnum } from '../Buttons/types';
import { TextInput } from '../Form/TextInput';

interface ISearchProps {
  id: string;
  name: string;
  placeholder: string;
  searchText: number | undefined | string;
  fieldName?: string;
  setSearchText: React.Dispatch<React.SetStateAction<number | undefined | string>>;
  handleSearch: (value?: number | undefined | string) => void;
  tableOptions: ISearchRequest;
  disabled?: boolean;
}

const Search = ({
  id,
  name,
  placeholder,
  searchText,
  fieldName,
  setSearchText,
  handleSearch,
  tableOptions,
  disabled,
}: ISearchProps) => {
  return (
    <div className="flex">
      <TextInput
        id={id}
        name={name}
        placeholder={placeholder}
        className="self-end"
        value={searchText}
        onChange={(e) => { setSearchText(e.target.value); }}
        onClear={() => {
          setSearchText(undefined);
          const filterExists = tableOptions.filters.find((filter) => filter.fieldName === (fieldName || 'name' || 'number'));
          filterExists && handleSearch(undefined);
        }}
        leadingIcon={<SearchIcon />}
        clearable
        onEnter={() => { !disabled && handleSearch(); }}
      />
      <Button
        variant={ButtonVariantEnum.SECONDARY}
        className="self-end ml-2"
        onClick={() => {
          handleSearch();
        }}
        disabled={disabled ?? false}
      >
        Search
      </Button>
    </div>
  );
};

export default Search;
