import { Auth0Role, Auth0User, Auth0UserMembership } from '@gr/shared/models';
import { Redirect } from 'react-router-dom';
import { useAuth0UserData } from '../hooks/useAuth0Custom';
import { Auth0FrontendConfig } from '../providers/auth0-data.provider';
import LoadingIndicator from './shared/LoadingIndicator';

interface ILoginProps {
  auth0Config: Auth0FrontendConfig | undefined;
}

const Login = ({
  auth0Config,
}: ILoginProps): JSX.Element => {
  const { isAuthenticated: isLoggedIn, isLoading: isAuth0Loading, loginWithRedirect, user: auth0User } = useAuth0UserData();

  const outboxRoles = [Auth0Role.GR_CLICKER];
  const twoWayRoles = [Auth0Role.GR_CLIENT_MANAGER, Auth0Role.GR_CLIENT_USER];
  const campaignRoles = [
    Auth0Role.GR_ADMIN,
    Auth0Role.GR_STAFF_MANAGER,
    Auth0Role.GR_STAFF_USER,
  ];

  const getPath = (user?: Auth0User): string => {
    const memberships: Auth0UserMembership[] = user?.app_metadata?.memberships ?? [];
    const membership = memberships.find((membership) => membership?.organization === auth0Config?.organization);
    const roles = membership?.roles ?? [];

    if (hasAnyRole(roles, outboxRoles)) {
      return '/app/my-campaigns';
    }

    if (hasAnyRole(roles, twoWayRoles)) {
      return '/app/conversations';
    }

    if (hasAnyRole(roles, campaignRoles)) {
      return '/app/campaigns';
    }

    return '/app/dashboard';
  };

  const hasAnyRole = (userRoles: Auth0Role[], targetRoles: Auth0Role[]) => {
    return userRoles?.some((role) => targetRoles?.includes(role));
  };

  if (isAuth0Loading) {
    console.log('Auth0: Loading');
    return <LoadingIndicator size={16} position="CENTER" vPosition="CENTER" />;
  }

  if (!isLoggedIn) {
    loginWithRedirect();
    return <></>;
  }

  return <Redirect to={{ pathname: getPath(auth0User as any) }} />;
};

export default Login;
