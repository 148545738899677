import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useState } from 'react';
import ErrorBanner from '../ErrorBanner/ErrorBanner';
import DetailsPanelFooter from './PanelFooter';
import { DetailsPanelSize, IButtonOptions } from './types';

interface IDetailsPanelProps {
  title: any;
  formId?: string; // Used so our footer can submit the form
  show: boolean;
  size?: DetailsPanelSize;
  children?: React.ReactNode;
  isDisabled?: boolean; // Disables the Save button
  isReadOnly?: boolean; // Hides the Delete and Save button
  loading?: boolean; // Puts a loading indicator on the Save button and disables it
  sticky?: boolean; // Controls if the panel can be closed by clicking out or hitting escape. Required to be false to allow for in-panel modals such as DatePickers
  errorMessage?: string; // Error message to show in a red panel on top of the details panel
  primaryButtonOptions?: IButtonOptions;
  leftPanelButtonOptions?: IButtonOptions[];
  closePanel: () => void;
  item?: any;
}

const DetailsPanel = ({
  title,
  formId,
  show,
  size,
  primaryButtonOptions = { text: 'Save', visible: true },
  leftPanelButtonOptions,
  children,
  isReadOnly,
  isDisabled,
  loading,
  sticky = false,
  errorMessage,
  closePanel,
  item,
}: IDetailsPanelProps) => {
  const [error, setError] = useState(errorMessage);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [showId, setShowId] = useState<boolean>(false);
  const [closing, setClosing] = useState(false);

  const onNameDoubleClick = () => {
    setShowId(!showId);

    try {
      const message = [
        item?.id ? `ID: ${item?.id}` : undefined,
      ]?.filter(v => !!v)?.join('\n') ?? 'N/A';

      navigator?.clipboard?.writeText(message);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setError(errorMessage);
    setShowErrorBanner(true);
  }, [errorMessage]);

  const close = () => {
    setShowId(false);
    closePanel();
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30 overflow-hidden"
        onClose={() => {
          !sticky && close();
        }}
      >
        <div className="absolute inset-0 h-full overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
              beforeLeave={() => setClosing(true)}
              afterLeave={() => setClosing(false)}
            >
              <div
                className={`w-screen ${size === DetailsPanelSize.Small
                  ? 'max-w-md'
                  : size === DetailsPanelSize.Large
                    ? 'max-w-6xl'
                    : 'max-w-2xl'
                  }`}
              >
                <div className="flex flex-col justify-between h-full bg-white shadow-xl dark:bg-slate-700 dark:text-slate-400">
                  {error && showErrorBanner && (
                    <ErrorBanner
                      message={error}
                      onDismissClick={() => {
                        setShowErrorBanner(false);
                        setError('');
                      }}
                    />
                  )}
                  <div className="flex flex-col flex-grow pt-6 overflow-y-scroll bg-white dark:bg-slate-700 dark:text-slate-400">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900 dark:text-white">
                          <div className='flex flex-row justify-center items-center'>
                            <div className='select-none' onDoubleClick={() => onNameDoubleClick()}>{title}</div>
                            {showId && item?.id && <div className='text-sm pl-2'>{item?.id}</div>}
                          </div>
                        </Dialog.Title>
                        <div className="flex items-center ml-3 h-7">
                          <button
                            type="button"
                            className="text-gray-400 bg-white rounded-md dark:bg-slate-700 dark:text-slate-400 dark:hover:bg-slate-800 hover:text-gray-500 dark:focus:ring-slate-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                            onClick={close}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="w-6 h-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="relative flex flex-col flex-1 px-4 mt-6 overflow-y-scroll sm:px-6">{children}</div>
                  </div>
                  <DetailsPanelFooter
                    number={item?.number}
                    formId={formId || ''}
                    loading={loading}
                    isDisabled={isDisabled || false}
                    isReadOnly={isReadOnly || false}
                    primaryButtonOptions={primaryButtonOptions}
                    leftPanelButtonOptions={leftPanelButtonOptions}
                    onCancel={close}
                    closing={closing}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export { DetailsPanel };

