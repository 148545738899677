import { useMembership } from '@gr/portal/contexts/MembershipContext';
import { getSubDomain } from '@gr/portal/providers/auth0-data.provider';
import { Auth0OrganizationData, P2P_ORG_ID } from '@gr/shared/models';
import { orderBy } from 'lodash';
import { Dropdown, IDropdownValue } from '../Dropdown';

interface ISubDomainDropdownProps {
  value: IDropdownValue | undefined;
  onChange: (newValue?: IDropdownValue | undefined) => void;
  onBlur?: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
}

const SubDomainDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
}: ISubDomainDropdownProps): JSX.Element => {
  const { organizations } = useMembership();

  const subDomain = getSubDomain();
  const isLocal = window.location.origin.includes('localhost');
  const preProdOrgNames = ['dev', 'dev2', 'staging', 'staging2'];
  const isPreProd = preProdOrgNames.includes(subDomain?.toLowerCase()?.trim());

  const options = organizations
    ?.filter(org => !!org && org?.id !== P2P_ORG_ID)
    ?.filter(org => (isPreProd || isLocal) ? preProdOrgNames.includes(org?.name?.toLowerCase()?.trim()) : true)
    ?.map(org => getOption(org))
    ?? [];

  const orderedOptions = isLocal
    ? orderBy(options, option => option.value === 'dev', 'desc')
    : orderBy(options, 'label');

  return (
    <Dropdown
      label="Organization"
      value={value}
      options={orderedOptions}
      onChange={onChange}
      onBlur={onBlur}
      error={errorMessage}
      disabled={disabled}
      full
      labelColor="black"
      className="px-3 pb-2"
    />
  );
};

function getOption(organization: Auth0OrganizationData): IDropdownValue {
  return {
    label: organization.display_name,
    value: organization.name,
    additionalData: {
      orgId: organization?.id,
    }
  };
}

export default SubDomainDropdown;
