import { ExternalV1GetTestNumbersResponse, ISearchRequest } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

/**
 * Interface for the request parameters used to fetch test numbers
 * 
 * @property clientId - The client ID to filter by
 * @property clientName - The client name to filter by
 * @property number - The number to filter by
 * @property skip - The number of records to skip
 * @property take - The number of records to take
 */
interface ITestNumberRequest {
  clientId?: string;
  clientName?: string;
  number?: number;
  skip?: number;
  take?: number;
}

/**
 * Custom React hook for fetching test numbers with filtering and pagination
 * 
 * @param tableOptions - Contains filter and pagination settings from the data table
 * @returns UseAxiosResult - Returns the axios hook result containing data, loading state, and error state
 */
export default function useTestNumbers(
  tableOptions: ISearchRequest
): UseAxiosResult<ExternalV1GetTestNumbersResponse> {
  /**
   * Helper function to get the filter value for a given field name
   * @param fieldName - The name of the field to get the filter value for
   * @returns The filter value for the given field name, or undefined if the field is not found
   */
  const getFilterValue = (fieldName: string) =>
    tableOptions.filters.find(filter => filter.fieldName === fieldName)?.value;

  /**
   * Constructs the search request object based on the table options
   * @returns The constructed search request object
   */
  const searchRequest: ITestNumberRequest = {
    clientId: getFilterValue('clientId')?.join(','),
    number: getFilterValue('number'),
    clientName: getFilterValue('clientName'),
    ...tableOptions.pagination
  };

  /**
   * Fetches the test numbers using the constructed search request
   * @returns The axios hook result containing data, loading state, and error state
   */
  return useAxios<ExternalV1GetTestNumbersResponse>({
    url: '/v1/test-numbers',
    method: 'GET',
    params: searchRequest
  });
};
