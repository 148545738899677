import { LocalStorageCache } from '@auth0/auth0-spa-js';
import { Auth0ApplicationID, Auth0FrontendUser, Auth0OrganizationName } from '@gr/shared/models';
import { AUTH0_SCOPE } from './auth0.service';

export interface Auth0LocalStorageToken {
  expiresAt: number;
  body: {
    access_token: string;
    audience: string;
    client_id: string;
    expires_in: number;
    oauthTokenScope: string;
    scope: string;
    token_type: string;
  };
}

export interface Auth0LocalStorage {
  user_id: string;
  sessions: Auth0Session[];
}

export interface Auth0Session {
  appId: Auth0ApplicationID;
  token?: Auth0LocalStorageToken | undefined;
  user?: Auth0FrontendUser | undefined;
}

export const P2P_SELECTED_ORG_KEY = 'p2p_selected_org_name';

let cache: LocalStorageCache | undefined = undefined;

export function getAuth0LocalStorage(user: Auth0FrontendUser): Auth0LocalStorage {
  const cache = getLocalStorageCache();
  const keys = cache.allKeys();

  const appIds = [...new Set(keys?.map(key => key?.split('::')?.[1] as Auth0ApplicationID) ?? [])];;

  const sessions: Auth0Session[] = [];

  for (const appId of appIds) {
    const appKeys = keys?.filter(key => key?.includes(appId));
    const userKey = appKeys?.find(key => key?.includes('@@user@@'));
    const tokenKey = appKeys?.find(key => key?.includes(AUTH0_SCOPE));

    const tokenData = parseStringByKey(tokenKey);
    const userData = parseStringByKey(userKey);

    if (userData?.decodedToken?.user?.user_id !== user?.user_id) {
      if (userKey) {
        cache.remove(userKey);
      }

      if (tokenKey) {
        cache.remove(tokenKey);
      }
    }

    sessions.push({
      appId,
      token: tokenData,
      user: userData?.decodedToken?.user,
    });
  }

  return {
    user_id: user?.user_id,
    sessions,
  };
}

export function getCachedSelectedOrgName(): Auth0OrganizationName {
  return localStorage.getItem(P2P_SELECTED_ORG_KEY) as Auth0OrganizationName;
}

export function setCachedSelectedOrgName(orgName: Auth0OrganizationName) {
  localStorage.setItem(P2P_SELECTED_ORG_KEY, orgName);
}

export function clearCachedSelectedOrgName() {
  localStorage.removeItem(P2P_SELECTED_ORG_KEY);
}

export function removeAllSessions() {
  const cache = getLocalStorageCache();
  const keys = cache?.allKeys() ?? [];

  for (const key of keys) {
    cache.remove(key);
  }

  clearCachedSelectedOrgName();
}

export function removeP2pSession() {
  const cache = getLocalStorageCache();
  const p2pKeys = cache?.allKeys()?.filter(key => key?.includes(Auth0ApplicationID.P2P)) ?? [];

  for (const p2pKey of p2pKeys) {
    cache.remove(p2pKey);
  }

  clearCachedSelectedOrgName();
}

function getLocalStorageCache() {
  return !!cache ? cache : new LocalStorageCache();
}

function parseStringByKey(key: string | undefined) {
  try {
    return !!key
      ? parseString(localStorage?.getItem?.(key) ?? undefined)
      : undefined;
  } catch (error) {
    console.log({ error });
    return undefined;
  }
}

function parseString(value: string | undefined) {
  try {
    return value ? JSON.parse(value) : undefined;
  } catch (error) {
    console.log({ error });
    return undefined;
  }
}
