import { Button, ButtonVariantEnum, CalculateIcon, Info, Radio, TextInput } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import { axiosPost } from '@gr/portal/authAxios';
import { useAuth0Context } from '@gr/portal/contexts/Auth0ConfigContext';
import {
  isFileLocalSize,
  isFileMediaType,
  isFileTollFreeSize,
} from '@gr/portal/providers/validation.provider';
import {
  BUCKETS,
  MessageTypeEnum,
  SystemNumberTypeEnum
} from '@gr/shared/enums';
import { ICalculateLeadsRequest, IHttpResponse, ISignedUrlRequest } from '@gr/shared/models';
import axios from 'axios';
import { FastField } from 'formik';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import NewAreaCodeDropdown from '../../shared/Form/Dropdowns/NewAreaCodeDropdown';
import NewAssignedAgentsDropdown from '../../shared/Form/Dropdowns/NewAssignedAgentsDropdown';
import NewCampaignTypeDropdown from '../../shared/Form/Dropdowns/NewCampaignTypeDropdown';
import NewClientDropdown from '../../shared/Form/Dropdowns/NewClientDropdown';
import NewContactListDropdown from '../../shared/Form/Dropdowns/NewContactListDropdown';
import NewStateDropdown from '../../shared/Form/Dropdowns/NewStateDropdown';
import NewSuppressionListDropdown from '../../shared/Form/Dropdowns/NewSuppressionListDropdown';
import { FileUpload } from '../../shared/Form/FileUpload';

interface IPlanProps {
  values?: any;
  errors?: any;
  touched?: any;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched?: (field: string, touched: boolean) => void;
  setFieldError?: (field: string, msg: string | undefined) => void;
  handleChange?: () => void;
  handleBlur?: () => void;
  onSubmit: (formikProps) => void;
  setLeads: (leads: any) => void;
  setLoading: (value: boolean) => void;
  allListsFailed: boolean;
  leads: number;
  submissionError?: string;
  formErrors?: { [key: string]: string; };
}

const Plan = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  handleChange,
  handleBlur,
  setLeads,
  setLoading,
  allListsFailed,
  leads,
  submissionError,
  formErrors
}: IPlanProps) => {
  const [calculateLoading, setCalculateLoading] = useState(false);
  const { auth0Config } = useAuth0Context();
  errors = { ...errors, ...formErrors };

  const calculateLeads = async (request: ICalculateLeadsRequest) => {
    setCalculateLoading(true);
    try {
      const response = await axiosPost('/contact-lists-calculate-leads', request);
      if (response && response.status === 200) {
        setLeads(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setCalculateLoading(false);
    }
  };

  const uploadMediaFile = async (
    file: File,
    numberType: SystemNumberTypeEnum,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const validateFileSize = numberType === SystemNumberTypeEnum.LOCAL ? isFileLocalSize : isFileTollFreeSize;

    if (!file || !isFileMediaType(file) || !validateFileSize(file)) {
      setFieldValue('mediaUrl', '');
      setFieldValue('mediaName', '');
      return;
    }

    const mediaFileKey: string = uuid();

    const s3SignedUrlRequest: ISignedUrlRequest = {
      bucket: BUCKETS.MMS,
      key: mediaFileKey,
      action: 'putObject',
      contentType: file.type,
    };

    const s3MediaUrlRequest: ISignedUrlRequest = {
      bucket: BUCKETS.MMS,
      key: mediaFileKey,
      action: 'getObject',
      contentType: file.type,
      expiresIn: 31536000, // 1 Year
    };

    try {
      setLoading(true);
      const {
        data: { data: uploadUrl },
      } = await axiosPost<IHttpResponse<string>>('/utility-get-signed-s3-url', s3SignedUrlRequest);

      await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
          'Content-Disposition': 'attachment',
        },
      });

      const baseUrl = window.location.hostname === 'localhost' ? 'https://dev.grassrootstexts.com' : auth0Config?.apiUrl.replace('api.', '');
      setFieldValue('mediaUrl', `${baseUrl}/mms-uploads/${mediaFileKey}`);
      setFieldValue('mediaName', file.name);
    } catch (err) {
      console.error('Error when attempting to upload media file');
    } finally {
      setLoading(false);
    }
  };

  if (allListsFailed) {
    errors.contactLists = 'Selected contact list failed to process and has been removed form the campaign';
  }

  return (
    <div className="flex flex-wrap space-y-8">
      <div className="campaign-plan">
        <h2 className="mb-8 text-black basis-full">Plan your message</h2>
        <FastField
          component={TextInput}
          id="name"
          name="name"
          label="Campaign Title"
          placeholder="Name your campaign"
          value={values.name}
          error={touched.name ? errors.name : ''}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <NewClientDropdown
          placeholder="Type to search for a client"
          onChange={async (newValue) => {
            await setFieldValue!('client', newValue);
            await setFieldTouched!('client', true);
            if (!newValue || (newValue as IDropdownValue).value !== values.client?.value) {
              setFieldValue!('campaignType', null);
            }

            // if clicker group is already set either by user or campaign has already been saved as draft
            // do not reset it to default clicker group of client
            if (!values.clickerGroup) {
              setFieldValue!('clickerGroup', (newValue as IDropdownValue)?.additionalData?.clickerGroup ?? '');
              setFieldTouched!('clickerGroup', true);
            }
          }}
          full
          value={values.client}
          onBlur={() => {
            setFieldTouched!('client', true);
          }}
          errorMessage={(errors?.client as any)?.value as string}
          showError={(touched?.client as any) ?? false}
        />
        <Radio
          label="Message Type"
          disabled={values.status !== 'DRAFT'}
          options={[
            {
              label: 'SMS',
              value: MessageTypeEnum.SMS,
            },
            {
              label: 'MMS',
              value: MessageTypeEnum.MMS,
            },
          ]}
          value={values.messageType}
          onChange={(newValue) => {
            setFieldValue!('messageType', newValue);
            setFieldTouched!('messageType', true);
            setFieldValue!('campaignType', undefined);
          }}
          grow
          error={touched.messageType && errors.messageType?.value}
        />

        <NewCampaignTypeDropdown
          clientId={values.client?.value}
          messageType={values.messageType}
          value={values.campaignType}
          onChange={(newValue: IDropdownValue | undefined) => {
            setFieldValue!('campaignType', newValue?.value);
          }}
          touched={touched.campaignType}
          errorMessage={errors.campaignType}
        />
        {values.messageType === MessageTypeEnum.MMS && (
          <>
            <div>
              <FileUpload
                id="mediaFile"
                name="mediaFile"
                label="Media File"
                fileName={values.mediaName}
                accepts="image/*, video/*, .pdf"
                value={values.mediaFile}
                error={errors.mediaFile as any}
                onBlur={handleBlur}
                placeholder="Select a media file"
                onChange={(fileUploadEvent: any) => {
                  const file = fileUploadEvent.target.files[0];
                  setFieldTouched!('mediaFile', true);
                  setFieldValue!('mediaFile', file);
                  if (file.type.match('video.*')) {
                    setFieldValue!('isMMSVideo', true);
                  } else {
                    setFieldValue!('isMMSVideo', false);
                  }
                  uploadMediaFile(file, SystemNumberTypeEnum.LOCAL, setFieldValue!); // TODO: Remove hardcode of local number type so file size is accurate
                }}
              />
            </div>
            <div>{/* SPACER */}</div>
          </>
        )}
        <div className="w-full border-b-2 border-light-gray shrink-0"></div>
        <h2 className="mb-8 text-black basis-full">Select Contacts</h2>
        <NewContactListDropdown
          value={values.contactLists}
          onChange={(newValue) => {
            setFieldValue!('contactLists', newValue);
          }}
          onBlur={() => {
            setFieldTouched!('contactLists', true);
          }}
          errorMessage={errors.contactLists as string}
          showError={!!(touched.contactLists && errors.contactLists) || allListsFailed}
        />

        <NewAssignedAgentsDropdown
          value={values.clickerGroup ?? undefined}
          onChange={(newValue) => {
            setFieldValue!('clickerGroup', newValue?.value);
            setFieldTouched!('clickerGroup', true);
          }}
          onBlur={() => {
            setFieldTouched!('clickerGroup', true);
          }}
          errorMessage={errors.clickerGroup as string}
          showError={!!(touched.client && errors.clickerGroup)}
          includeNone={!values.client?.additionalData?.clickerGroup}
        />
        <FastField
          component={TextInput}
          id="externalId"
          name="externalId"
          label={
            <div className="flex items-center mb-5">
              <h5>{'EXTERNAL ID (OPTIONAL)'}</h5>
              <Info className="ml-1" text="Include an ID from an external source." />
            </div>
          }
          value={values.externalId}
          errors={errors.externalId}
          touched={touched.externalId}
          onChange={handleChange}
        />
        <Radio
          label="DO YOU NEED TO SUPPRESS CONTACTS FROM A LIST OR AREA?"
          options={[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]}
          value={values.showSuppressions}
          grow
          onChange={(newValue) => {
            setFieldValue!('showSuppressions', newValue);
          }}
        />
        {values.showSuppressions && (
          <>
            <h2 className="mb-8 text-black basis-full">Contact Suppression</h2>
            <NewSuppressionListDropdown
              value={values.suppressionLists}
              contactListIds={values.contactLists.map((dv) => dv.value)}
              onChange={(newValue) => {
                setFieldValue!('suppressionLists', newValue);
              }}
              onBlur={() => {
                setFieldTouched!('suppressionLists', true);
              }}
              errorMessage={errors.suppressionLists as string}
              showError={!!(touched.suppressionLists && errors.suppressionLists)}
            />
            <div className="flex w-full gap-x-8">
              <NewStateDropdown
                value={values.suppressionStates}
                onChange={(newValue) => {
                  setFieldValue!('suppressionStates', newValue);
                }}
                onBlur={() => {
                  setFieldTouched!('suppressionStates', true);
                }}
                errorMessage={errors.suppressionStates as string}
                showError={!!(touched.suppressionStates && errors.suppressionStates)}
              />
              <NewAreaCodeDropdown
                value={values.suppressionAreaCodes}
                onChange={(newValue) => {
                  setFieldValue!('suppressionAreaCodes', newValue);
                }}
                onBlur={() => {
                  setFieldTouched!('suppressionAreaCodes', true);
                }}
                errorMessage={errors.suppressionAreaCodes as string}
                showError={!!(touched.suppressionAreaCodes && errors.suppressionAreaCodes)}
              />
            </div>
          </>
        )}
        <div className='flex justify-between'>
          <div className="flex items-center">
            <Button
              variant={ButtonVariantEnum.GREEN}
              onClick={() =>
                calculateLeads({
                  clientId: values.client?.value,
                  contactListIds: values.contactLists?.map((cl) => cl.value),
                  suppressionListIds: values.suppressionLists?.map((sl) => sl.value),
                  suppressionAreaCodes: values.suppressionAreaCodes?.map((sa) => sa?.additionalData?.toString()),
                  suppressionStateCodes: values.suppressionStates?.map((ss) => ss.value),
                  campaignType: values.campaignType,
                  campaignMessageType: values.messageType,
                })
              }
              disabled={!values.campaignType || values.contactLists?.length <= 0}
              type="button"
              trailingIcon={
                <div className={calculateLoading ? 'spin' : ''}>
                  <CalculateIcon />
                </div>
              }
            >
              Calculate Campaign Volume
            </Button>
            <h3 className="ml-8 text-wc-blue">{leads} messages</h3>
          </div>
          {submissionError && <h3 className='flex items-center mt-3 text-red-600'>{submissionError}</h3>}
        </div>
      </div>
    </div>
  );
};

export default Plan;
