import { axiosGet } from '@gr/portal/authAxios';
import { IHttpResponse } from '@gr/shared/models';
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment, useEffect, useState } from 'react';
import { Button } from '../Buttons/Button';
import { ButtonVariantEnum } from '../Buttons/types';
import LoadingIndicator from '../LoadingIndicator';
import { IButtonOptions } from './types';

interface IDetailsPanelFooter {
  isDisabled: boolean;
  isReadOnly: boolean;
  loading?: boolean;
  closing: boolean;
  formId: string;
  primaryButtonOptions?: IButtonOptions;
  leftPanelButtonOptions?: IButtonOptions[];
  onCancel?: () => void;
  number: number;
}

const DetailsPanelFooter = ({
  isDisabled,
  isReadOnly,
  closing,
  loading,
  formId,
  primaryButtonOptions,
  leftPanelButtonOptions,
  onCancel,
  number
}: IDetailsPanelFooter) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);

  const buttonOptions = leftPanelButtonOptions ?? [];

  const getButtonText = (buttonOption: IButtonOptions) => {
    return buttonOption.loading ? <LoadingIndicator position="CENTER" /> : buttonOption.text;
  };

  const [data, setData] = useState('Are you sure you want to delete this item? The test number will be removed from - clients.');

  useEffect(() => {
    const fetchData = async () => {
      if (number) {
        const clients = await axiosGet<IHttpResponse<any>>(`/active-clients?number=${number}`);
        setData(`Are you sure you want to delete this item?  The test number will be removed from ${clients.data.data} clients.`);
      }
    };
    fetchData();
  }, []);

  const getConfirmationMessage = (buttonOption: IButtonOptions) => {
    if (buttonOption.text === 'Delete All') {
      return (
        `${data}`
      );
    }
    return (
      buttonOption?.confirmationMessage ??
      `Are you sure you want to ${buttonOption?.text?.toLocaleLowerCase()} this item?`
    );
  };

  return (
    <>
      <div className="flex justify-end pt-4 pb-10 pl-6 pr-10 space-x-2">
        {!showDeleteConfirmation && (
          <div className="flex self-end mr-auto space-x-2">
            {buttonOptions.map((buttonOption, index) => {
              return (
                buttonOption?.visible && (
                  <Button
                    key={`${buttonOption.text}_left_panel`}
                    onClick={() => {
                      setSelectedButtonIndex(index);

                      if (buttonOption.hideConfirmation) {
                        setShowDeleteConfirmation(false);
                        buttonOption?.onClick?.();
                      } else {
                        setShowDeleteConfirmation(true);
                      }
                    }}
                    type="button"
                    variant={buttonOptions[index]?.variant ?? ButtonVariantEnum.DELETE}
                    className="ml-0"
                  >
                    {getButtonText(buttonOption)}
                  </Button>
                )
              );
            })}
          </div>
        )}

        <Transition
          appear
          show={showDeleteConfirmation}
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className={`flex flex-col w-full max-w-md p-6 pb-0 m-4 mb-0 mr-auto text-left self-end transition-all transform ${buttonOptions[selectedButtonIndex]?.variant === ButtonVariantEnum.DELETE ? 'bg-red-100' : 'bg-sky-100'
              } rounded-md shadow-xl`}
          >
            <Button
              className="absolute top-0 right-0 rounded-tr-md hover:bg-white"
              variant={ButtonVariantEnum.TEXT_DEFAULT}
              leadingIcon={<XIcon className="w-5 h-5 text-gray-300" />}
              onClick={() => {
                setShowDeleteConfirmation(false);
              }}
            />
            <p className="pb-4 text-md">{getConfirmationMessage(buttonOptions[selectedButtonIndex])}</p>
            <Button
              onClick={buttonOptions[selectedButtonIndex]?.onClick}
              disabled={buttonOptions[selectedButtonIndex]?.loading}
              type="button"
              variant={buttonOptions[selectedButtonIndex]?.variant ?? ButtonVariantEnum.DELETE}
              className={`mb-2 ml-auto border  rounded-md ${buttonOptions[selectedButtonIndex]?.variant === ButtonVariantEnum.DELETE
                ? 'border-red-200'
                : 'border-sky-200'
                }`}
            >
              {buttonOptions[selectedButtonIndex]?.loading ? (
                <div className="flex justify-center">
                  <div
                    className={`w-5 h-5 ease-linear border-2 border-t-2 border-gray-200 rounded-full ${buttonOptions[selectedButtonIndex]?.variant === ButtonVariantEnum.DELETE
                      ? 'border-t-red-500'
                      : 'border-t-sky-500'
                      } loader`}
                  ></div>
                </div>
              ) : (
                `Confirm ${buttonOptions[selectedButtonIndex]?.text}`
              )}
            </Button>
          </div>
        </Transition>
        {onCancel && (
          <Button
            id="panel-close"
            variant={ButtonVariantEnum.DEFAULT}
            type="button"
            onClick={onCancel}
            className="self-end"
          >
            Cancel
          </Button>
        )}
        {!isReadOnly && primaryButtonOptions?.visible && !closing && (
          <Button
            disabled={isDisabled || loading}
            type="submit"
            variant={ButtonVariantEnum.SECONDARY}
            id="panel-save"
            className="self-end"
            formId={formId}
          >
            {loading ? <LoadingIndicator position="CENTER" /> : `${primaryButtonOptions?.text}`}
          </Button>
        )}
      </div>
    </>
  );
};

export default DetailsPanelFooter;
