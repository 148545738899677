import { LogoutOptions, User } from '@auth0/auth0-react';
import { Auth0CreateUserRequest, Auth0GetUserDetailsRequest, Auth0OrganizationName, Auth0UpdateUserRequest, Auth0UserDetails, Auth0UserMembership, IHttpResponse } from '@gr/shared/models';
import { getRedirectUrlByOrg } from '@gr/shared/utils';
import { axiosPost } from '../authAxios';
import { IUserForm } from '../components/AdminSettings/types';
import { Auth0FrontendConfig } from './auth0-data.provider';

export type Auth0Logout = (options?: LogoutOptions | undefined) => void;

export const AUTH0_SCOPE = "openid profile email offline_access";
export const AUTH0_AUDIENCE = "grassroots";

export interface Auth0LogoutRequest {
  auth0Config: Auth0FrontendConfig;
  auth0Logout: Auth0Logout;
}

export function logout({ auth0Config, auth0Logout }: Auth0LogoutRequest) {
  auth0Logout({
    logoutParams: {
      returnTo: getRedirectUrlByOrg({
        baseUrl: window?.location?.origin,
        orgId: auth0Config.organizationId,
        orgName: auth0Config.organization,
      })
    }
  });
}

export interface CreateAuth0ScopeRequest {
  orgName: Auth0OrganizationName;
}

export function createAuth0Scope({ orgName }: CreateAuth0ScopeRequest) {
  return `${AUTH0_SCOPE} ${orgName}`;
}

export function getUserMemberships(auth0User: User | undefined): Auth0UserMembership[] {
  return auth0User?.app_metadata?.memberships ?? [];
}

export async function getUserDetails(request: Auth0GetUserDetailsRequest) {
  const response = await axiosPost<IHttpResponse<Auth0UserDetails>>('/auth0-get-user-details', request);
  return response.data.data;
}

export async function createUser(form: IUserForm) {
  const request: Auth0CreateUserRequest = {
    email: form.email.trim(),
    firstName: form.firstName.trim(),
    lastName: form.lastName.trim(),
    memberships: [
      {
        roles: [form.role.value],
        clickerGroupId: form.clickerGroupId ?? undefined,
        clickerGroupApplied: false
      },
    ],
    clientIds: form.clients.map(c => c.value)
  };

  await axiosPost<IHttpResponse<string>>('/auth0-create-user', request);
}

export async function updateUser(details: Auth0UserDetails, form: IUserForm) {
  const request: Auth0UpdateUserRequest = {
    user_id: details.user_id,
    email: form.email.trim(),
    firstName: form.firstName.trim(),
    lastName: form.lastName.trim(),
    memberships: [
      {
        roles: [form.role.value],
        clickerGroupId: form.clickerGroupId ?? undefined,
        clickerGroupApplied: false
      },
    ],
    clientIds: form.clients.map(c => c.value)
  };

  await axiosPost<IHttpResponse<string>>('/auth0-update-user', request);
}
