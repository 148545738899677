import { roundToNextQuarterHour } from '@gr/portal/providers/utility.provider';
import { CampaignStatusEnum, UiCampaignStatusEnum } from '@gr/shared/enums';
import { FilterDataTypeEnum, FilterOperatorEnum, ISearchFilter, ISendingWindow } from '@gr/shared/models';
import { isInSendingWindow } from '@gr/shared/time';
import { addDays, subMinutes } from 'date-fns';
import { DateTime } from 'luxon';

export const getCampaignStartDate = (): Date => {
  const startDate = roundToNextQuarterHour(new Date());

  return startDate;
};

export const getCampaignEndDateForStartDate = (startDate: Date) => {


  const startDateEst = DateTime.fromJSDate(startDate, { zone: 'America/New_York' });
  const est = DateTime.local({ zone: 'America/New_York' });
  const estOffset = Math.abs(est.offset / 60);
  const localOffset = startDate.getTimezoneOffset() / 60;

  const date = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDateEst.day + 1, localOffset - (localOffset - estOffset), 0));

  // To skip confusion over midnight
  if (date.getHours() === 0) {
    return subMinutes(date, 1);
  }

  return date;
};

export const getCampaignEndDateForClone = (startDate: Date, endDate: Date) => {
  const startDateEst = DateTime.fromJSDate(startDate, { zone: 'America/New_York' });
  const endDateEst = DateTime.fromJSDate(endDate, { zone: 'America/New_York' });
  const date = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDateEst.day > startDateEst.day ? endDateEst.day : startDateEst.day, endDate.getUTCHours(), endDate.getMinutes()));

  if (startDate > date) {
    return getCampaignEndDateForStartDate(startDate);
  }

  return date;
};

export function isMoreThanTwoDays(startDate: Date, endDate: Date): boolean {
  return endDate > getCampaignEndDateForStartDate(addDays(startDate, 2));
}

export interface GetUIStatusRequest {
  status: CampaignStatusEnum;
  startsAt: Date;
  totalMessagesConfirmed: number;
  totalMessagesScheduled: number;
  totalMessagesSent: number;
  sendingWindow: ISendingWindow;
  allListsClassified: boolean;
}

export const mapToUiStatus = (request: GetUIStatusRequest): UiCampaignStatusEnum => {
  switch (request.status) {
    case CampaignStatusEnum.LIVE:
      return getLiveUiStatus(request);
    case CampaignStatusEnum.COMPLETED:
      return request.totalMessagesScheduled === request.totalMessagesSent ? UiCampaignStatusEnum.COMPLETED : UiCampaignStatusEnum.INCOMPLETE;
    case CampaignStatusEnum.FAILED:
      return UiCampaignStatusEnum.DID_NOT_SCHED;
    case CampaignStatusEnum.SCHEDULING:
      return !request.allListsClassified ? UiCampaignStatusEnum.PENDING : UiCampaignStatusEnum.SCHEDULING;
    case CampaignStatusEnum.PAUSED:
      return UiCampaignStatusEnum.PAUSED;
    default:
      return UiCampaignStatusEnum.DRAFT;
  }
};

export function getLiveUiStatus(request: GetUIStatusRequest) {
  const now = new Date();
  const startsAt = new Date(request.startsAt!.toString());

  if (startsAt > now) {
    return UiCampaignStatusEnum.SCHEDULED;
  } else if (!isInSendingWindow(request.sendingWindow)) {
    return UiCampaignStatusEnum.QUEUED;
  } else {
    return request.totalMessagesConfirmed === 0
      ? UiCampaignStatusEnum.ACTIVE
      : UiCampaignStatusEnum.SENDING;
  }
}

export type CampaignStatusFilter = {
  value: CampaignStatusEnum;
  additionalFilters?: ISearchFilter[];
};

export const mapToStatusFilter = (status: UiCampaignStatusEnum, sendingWindow: ISendingWindow | undefined): CampaignStatusFilter => {
  // This filter always returns an empty result
  const EMPTY_RESULTS_FILTER: ISearchFilter = {
    fieldName: 'createdAt',
    dataType: FilterDataTypeEnum.DATE,
    operator: FilterOperatorEnum.NOT_EQUALS,
    value: 'createdAt',
    isValueField: true
  };

  const inSendingWindow = sendingWindow && isInSendingWindow(sendingWindow);

  switch (status) {
    case UiCampaignStatusEnum.SCHEDULED: {
      const additionalFilters: ISearchFilter[] = [{
        fieldName: 'startsAt',
        dataType: FilterDataTypeEnum.DATE,
        operator: FilterOperatorEnum.GREATER_THAN,
        value: new Date()
      }];

      return {
        value: CampaignStatusEnum.LIVE,
        additionalFilters
      };
    }

    case UiCampaignStatusEnum.QUEUED: {
      const additionalFilters: ISearchFilter[] = [{
        fieldName: 'startsAt',
        dataType: FilterDataTypeEnum.DATE,
        operator: FilterOperatorEnum.LESS_THAN_OR_EQUALS,
        value: new Date()
      }];

      if (inSendingWindow) {
        additionalFilters.push(EMPTY_RESULTS_FILTER);
      }

      return {
        value: CampaignStatusEnum.LIVE,
        additionalFilters
      };
    }

    case UiCampaignStatusEnum.ACTIVE:
      const additionalFilters: ISearchFilter[] = [{
        fieldName: 'startsAt',
        dataType: FilterDataTypeEnum.DATE,
        operator: FilterOperatorEnum.LESS_THAN_OR_EQUALS,
        value: new Date()
      }, {
        fieldName: 'totalMessagesConfirmed',
        dataType: FilterDataTypeEnum.NUMBER,
        operator: FilterOperatorEnum.EQUALS,
        value: 0
      }];

      if (!inSendingWindow) {
        additionalFilters.push(EMPTY_RESULTS_FILTER);
      }

      return {
        value: CampaignStatusEnum.LIVE,
        additionalFilters
      };

    case UiCampaignStatusEnum.SENDING: {
      const additionalFilters: ISearchFilter[] = [{
        fieldName: 'startsAt',
        dataType: FilterDataTypeEnum.DATE,
        operator: FilterOperatorEnum.LESS_THAN_OR_EQUALS,
        value: new Date()
      }, {
        fieldName: 'totalMessagesConfirmed',
        dataType: FilterDataTypeEnum.NUMBER,
        operator: FilterOperatorEnum.NOT_EQUALS,
        value: 0
      }];

      if (!inSendingWindow) {
        additionalFilters.push(EMPTY_RESULTS_FILTER);
      }

      return {
        value: CampaignStatusEnum.LIVE,
        additionalFilters
      };
    }

    case UiCampaignStatusEnum.COMPLETED:
      return {
        value: CampaignStatusEnum.COMPLETED,
        additionalFilters: [{
          fieldName: 'totalMessagesScheduled',
          dataType: FilterDataTypeEnum.NUMBER,
          operator: FilterOperatorEnum.EQUALS,
          value: 'totalMessagesSent',
          isValueField: true
        }]
      };

    case UiCampaignStatusEnum.INCOMPLETE:
      return {
        value: CampaignStatusEnum.COMPLETED,
        additionalFilters: [{
          fieldName: 'totalMessagesScheduled',
          dataType: FilterDataTypeEnum.NUMBER,
          operator: FilterOperatorEnum.NOT_EQUALS,
          value: 'totalMessagesSent',
          isValueField: true
        }]
      };

    case UiCampaignStatusEnum.PENDING:
      return {
        value: CampaignStatusEnum.SCHEDULING,
        additionalFilters: [{
          fieldName: 'allListsClassified',
          dataType: FilterDataTypeEnum.BOOLEAN,
          operator: FilterOperatorEnum.EQUALS,
          value: false
        }]
      };

    case UiCampaignStatusEnum.DID_NOT_SCHED:
      return { value: CampaignStatusEnum.FAILED };

    case UiCampaignStatusEnum.DRAFT:
      return { value: CampaignStatusEnum.DRAFT };

    case UiCampaignStatusEnum.PAUSED:
      return { value: CampaignStatusEnum.PAUSED };

    case UiCampaignStatusEnum.SCHEDULING:
      return { value: CampaignStatusEnum.SCHEDULING };

    default:
      return { value: CampaignStatusEnum.DRAFT };
  }
};
