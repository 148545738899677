import { Auth0FrontendUser, Auth0OrganizationID, Auth0OrganizationName } from '../models';

export function isPreProdSubdomain(subDomain: string) {
  return ['dev', 'dev2', 'staging', 'staging2'].includes(subDomain);
}

export function getRedirectSubdomain(subDomain: string) {
  switch (subDomain) {
    case 'dev':
    case 'dev2':
      return 'dev';

    case 'staging':
    case 'staging2':
      return 'staging';

    default:
      return undefined;
  }
}

export function getRedirectUrl(url: string) {
  const obj = new URL(url);
  const parts = obj.hostname.split('.');

  if (parts.length > 2) {
    const domain = parts.slice(-2).join('.');

    const subDomain = parts?.[0];

    obj.hostname = isPreProdSubdomain(subDomain)
      ? `${getRedirectSubdomain(subDomain)}.${domain}`
      : domain;
  }

  return obj.href;
}

export interface GetRedirectUrlByOrgRequest {
  baseUrl: string;
  orgName: Auth0OrganizationName | undefined;
  orgId: Auth0OrganizationID | undefined;
}

export function getRedirectUrlByOrg({ baseUrl, orgId, orgName }: GetRedirectUrlByOrgRequest) {
  return `${getRedirectUrl(baseUrl)}${getRedirectUrlParams({ orgId, orgName })}`;
}

interface GetRedirectUrlParamsRequest {
  orgId?: Auth0OrganizationID;
  orgName?: Auth0OrganizationName;
}

function getRedirectUrlParams({ orgId, orgName }: GetRedirectUrlParamsRequest) {
  if (!orgName && !orgId) {
    return '';
  }

  const params: string[] = [];

  if (orgName) {
    params.push(`org=${orgName}`);
  }

  if (orgId) {
    params.push(`organization=${orgId}`);
  }

  return `?${params.join('&')}`;
}

export function addParam(url: string, param: string) {
  return url?.includes('?')
    ? `${url}&${param}`
    : `${url}?${param}`;
}

export function getUserOrganizations(auth0User: Auth0FrontendUser) {
  const isLocal = window.location.origin.includes('localhost');
  const preProdOrgNames = ['dev', 'staging', 'dev2', 'staging2'];

  return isLocal
    ? auth0User?.organizations?.filter(org => preProdOrgNames.includes(org?.name) ?? [])
    : auth0User?.organizations ?? [];
}