import { Autocomplete } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import useTestNumbers from '@gr/portal/hooks/useTestNumbers';
import { FilterDataTypeEnum, FilterOperatorEnum, ISearchFilter } from '@gr/shared/models';
import { defaultTestNumberDropdownOptions } from '../../../HealthCheckNumbers/types';

interface ITestNumbersDropdownProps {
  value: number[] | undefined;
  onChange: (newValue?: (IDropdownValue | undefined)[]) => void;
  onBlur?: () => void;
  allClients?: boolean;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError: boolean;
  client: string;
  loading?: boolean;
}

/**
 * TestNumbersDropdown is a reusable form component that provides a searchable dropdown
 * for selecting test message contacts/numbers. It's primarily used in messaging and 
 * health check features of the Grassroots Portal.
 * 
 * Key features:
 * - Supports multiple selection (up to 20 numbers)
 * - Provides search functionality for test numbers
 * - Handles both client-specific and all-client test numbers
 * - Sorts client-specific numbers before general numbers
 * 
 * @param value - Currently selected test numbers
 * @param onChange - Callback function when selection changes
 * @param onBlur - Optional callback for blur events
 * @param disabled - Whether the dropdown is disabled
 * @param errorMessage - Error message to display if validation fails
 * @param showError - Whether to show the error message
 * @param client - Client identifier for filtering numbers
 * @param loading - Loading state indicator
 */
const TestNumbersDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
  client,
  loading,
}: ITestNumbersDropdownProps): JSX.Element => {
  const [{
    data: testNumbersData,
    loading: testNumbersLoading,
    error: testNumbersError
  }, searchTestNumbers] =
    useTestNumbers(defaultTestNumberDropdownOptions(client));
  const allClientNumbers = new Set();
  const testNumbers: IDropdownValue[] = (Array.isArray(testNumbersData?.data)
    ? testNumbersData.data
    : []
  ).map((testNumber) => {
    testNumber.allClients ?? allClientNumbers.add(testNumber.id);
    return {
      label: `${testNumber.name} - ${testNumber.number.toString()}`,
      value: testNumber.id,
      additionalData: {
        allClients: testNumber.allClients,
        number: testNumber.number,
      },
    };
  })
    .filter(
      (tn) => !allClientNumbers.has(tn.value) || (allClientNumbers.has(tn.value) && tn.additionalData.allClients)
    )
    .sort((a, b) =>
      // Don't move if equal, move client specific numbers first
      a.additionalData.allClients === b.additionalData.allClients ? 0 : a.additionalData.allClients ? 1 : -1
    ) ?? [];

  /**
   * Handles searching test numbers based on user input.
   * Creates a search filter for the 'number' field and combines it with existing filters.
   * 
   * @param searchValue - The search string entered by the user
   */
  const handleSearchTestNumbers = async (searchValue: string) => {
    let searchFilter: ISearchFilter = {
      dataType: FilterDataTypeEnum.STRING,
      fieldName: 'number',
      operator: FilterOperatorEnum.CONTAINS,
      value: searchValue,
    };
    const newSearchOptions = { ...defaultTestNumberDropdownOptions(client) };

    const nonSearchFilters = defaultTestNumberDropdownOptions(client).filters.filter(
      (filter) => filter.fieldName !== 'number'
    );

    newSearchOptions.filters = searchFilter.value ? [...nonSearchFilters, searchFilter] : [...nonSearchFilters];
    try {
      await searchTestNumbers({ data: newSearchOptions });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Autocomplete
      label="SELECT TEST MESSAGE CONTACTS"
      placeholder="Type to search contacts"
      loading={testNumbersLoading || loading}
      value={testNumbers.filter((tn) => value?.includes(tn?.additionalData?.number))}
      options={testNumbers}
      onChange={onChange}
      onBlur={onBlur}
      error={errorMessage || testNumbersError?.message}
      disabled={disabled}
      multiple
      selectAll
      showError={showError}
      readOnly={(value?.length ?? 0) >= 20}
    />
  );
};

export default TestNumbersDropdown;
