import { ISocketBroadcastPayload } from '@gr/shared/models';
import React, { createContext } from 'react';
import { Auth0FrontendConfig } from '../providers/auth0-data.provider';
import { SocketManager } from '../providers/socket.provider';

let socketManager: SocketManager;
let SocketContext: React.Context<SocketManager | null>;
let SocketContextProvider: React.Provider<SocketManager | null>;
let SocketContextConsumer: React.Consumer<SocketManager | null>;

function initializeWebSocket(config: Auth0FrontendConfig) {
  if (socketManager) {
    return;
  }

  const socket = new WebSocket(config.websocketUrl);

  socket.onopen = (ev: Event) => {
    console.log('Socket connected successfully');
  };

  socket.onmessage = (ev: MessageEvent<any>) => {
    const message: ISocketBroadcastPayload = JSON.parse(ev.data);

    console.log(message);

    const subscriptions = socketManager.subscriptions.get(message.eventName) ?? [];

    subscriptions.forEach((sub) => sub(message));
  };

  socketManager = new SocketManager(socket);
  SocketContext = createContext<SocketManager | null>(null);
  SocketContextProvider = SocketContext.Provider;
  SocketContextConsumer = SocketContext.Consumer;
}

export { initializeWebSocket, SocketContext, SocketContextConsumer, SocketContextProvider, socketManager };

